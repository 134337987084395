import { Panel } from "../../bananaframework/src/controls/Panel.js";
import { Image } from "../../bananaframework/src/controls/Image.js";
import { DataGridTileItemRender } from "../../bananaframework/src/controls/listcontrols/datagrids/listrenders/DataGridTileItemRender.js";
import { TableRow, TableHeaderCol, TableCol } from "../../bananaframework/src/controls/Table.js";
import { FavoriteTeamAdderModal } from "./favoriteteamaddermodal.js";
import { FavoriteLeagueAdderModal } from "./favoriteleagueaddermodal.js";
import { ImagePlaceHolder, teamPlaceholder, countryPlaceHolder } from "../imageplaceholder.js";
import { MatchUtil } from "../../util/matchutil.js";
import { Subscriber } from "../../util/subscriber.js";
import { Localizer } from "../../util/localizer.js";
import { Colorizer } from "../../util/color.js";
import { DateTimecode } from "../../util/datetime.js";
import { Router } from "../../util/router.js";
import { Favorites } from "../../util/favorites.js";
import {LiveStatsHelper} from "../../util/homestats.js";
import {ManiaPreferences} from "../../util/preferences.js";
import {RemoveReservedIfNeeded} from "../../util/utils.js";
import {Link} from "../../bananaframework/src/controls/Link.js";
import {FocusableItemRender} from "../itemrenders.js";
import {Remapper}   from "../../util/remapper.js";
import {ManiaAds} from "../../util/ads.js";

export class FavoriteTeamAdderItemRender extends FocusableItemRender{
    init () {
        super.init();
        this.panel = new Link();
        this.panel.addCssClass("flexHorizontalAlign listViewItemFavorites");
        this.addControl(this.panel);
        var con = new Panel();
        con.addCssClass("flexHorizontalAlign");
        this.imageBox = new Panel();
        this.imageBox.addCssClass("icon-add-favorite");
        this.imageBox.setStyle("width:40px;height:40px;margin-inline-start:4px;");
        this.imageBox.placeholderData = countryPlaceHolder;
        this.contents = new Panel();
        this.contents.addCssClass("listViewCountryTitle");
        this.contents.setStyle("margin-inline-start: 0px;");
        con.addControl(this.imageBox);
        con.addControl(this.contents);
        this.panel.addControl(con);
        this.panel.addControl(this.isLiveInfo);
        this.panel.bind('click', () => {
            this.openModal();
        });
    }
    getFocusable() {
        return this.panel;
    }
    setData (data) {
        this.data = data;
        this.contents.clear();
        this.panel.setCss({
            "height": data.style.height + "px"
        });
        this.imageBox.alt = data.name;
        this.contents.addControl(Localizer.getString("tap_to_add"));
        this.invalidateDisplay();
    }
    openModal () {
        var urlIdentifier = "teamSelectModal";
        var pan = new FavoriteTeamAdderModal(urlIdentifier);
        this.modal = pan;
        this.getPage().addControl(pan, true);
    }
};
export class FavoriteLeagueAdderItemRender extends  FavoriteTeamAdderItemRender{
    openModal () {
        var urlIdentifier = "leagueSelectModal";
        var pan = new FavoriteLeagueAdderModal(urlIdentifier);
        this.modal = pan;
        this.getPage().addControl(pan, true);
    }
};
export class FavoriteLeagueItemRender extends  FocusableItemRender{
    init () {
        super.init();
        this.panel = new Link();
        this.panel.addCssClass("flexHorizontalAlign listViewItemFavorites");
        this.addControl(this.panel);
        var con = new Panel();
        con.addCssClass("flexHorizontalAlign");
        this.imageBox = new ImagePlaceHolder();
        this.imageBox.addCssClass("listViewCountryFlagItem");
        this.imageBox.placeholderData = countryPlaceHolder;
        this.contents = new Panel();
        this.contents.addCssClass("listViewCountryTitle");
        this.isLiveInfo = new Panel();
        this.isLiveInfo.addCssClass("listViewFavoriteIsLiveInfo listViewFavoriteIsLiveInfoStatus live");
        con.addControl(this.imageBox);
        con.addControl(this.contents);
        this.panel.addControl(con);
        this.panel.addControl(this.isLiveInfo);
        this.panel.bind('click', () => {
            if (this.stopClickPropogation) return;
            Router.showLeagueScreen(this.data);
        });
        this.bindEvents();
    }

    getFocusable() {
        return  this.panel;
    }

    bindEvents () {
        this.panel.bind('click', () => {
            Router.showTeamScreen(this.data.id);
        });
        var startY = 0;
        var startX = 0;
        this.panel.bind("touchstart", e => {
            startY = e.originalEvent.touches[0].clientY;
            startX = e.originalEvent.touches[0].clientX;
            this.longPressHandler = setTimeout(() => {
                this.listRender.triggerEvent("requestActivateDrag", {
                    type: "league",
                    data: this.data
                });
            }, 500);
            this.panel.bind("touchmove", e => {
                if (Math.abs(startY - e.originalEvent.touches[0].clientY) > 10) {
                    clearTimeout(this.longPressHandler);
                    this.panel.unbind("touchmove", e.handleObj.handler);
                }
                if (Math.abs(startX - e.originalEvent.touches[0].clientX) > 10) {
                    clearTimeout(this.longPressHandler);
                    this.panel.unbind("touchmove", e.handleObj.handler);
                }
            });
        });
        this.panel.bind("touchend", () => {
            clearTimeout(this.longPressHandler);
        });
    }
    setData (data) {
        console.log("setData",data);
        this.data = data;
        //this._super(data);
        this.contents.clear();
        this.panel.setCss({
            "height": data.style.height + "px"
        });
        this.imageBox.alt = data.name;

        data.country = Remapper.remapCountryLogo(data.key,data.country);

        console.log("favorite ",data.key);
        this.imageBox.setImage(AppInstance.getConfig().url.imageBaseUrl + "/footapi/images/flags/" + data.country.replaceAll(" ", "-").toLowerCase() + ".png", 40, 40);
        //this.imageBox.setImage(AppInstance.getConfig().url.imageBaseUrl + "/footapi/images/leagueimages/" + data.key + ".png", 40, 40);
        this.contents.addControl(data.name);
        this.isLiveInfo.clear();
        if (data.is_live) {
            this.isLiveInfo.addControl(Localizer.getString("live"));
        }
        this.invalidateDisplay();
    }
};
export class FavoriteTeamItemRender extends  FocusableItemRender{
    init () {
        super.init();
        this.panel = new Link();
        //this.panel.setAttribute("tabindex", "0");
        this.panel.addCssClass("flexHorizontalAlign listViewItemFavorites");
        this.addControl(this.panel);
        var con = new Panel();
        con.addCssClass("flexHorizontalAlign");
        this.imageBox = new ImagePlaceHolder();
        this.imageBox.addCssClass("listViewCountryFlagItem");
        this.imageBox.placeholderData = teamPlaceholder;
        this.contents = new Panel();
        this.contents.addCssClass("listViewCountryTitle");
        this.isLiveInfo = new Panel();
        this.isLiveInfo.addCssClass("listViewFavoriteIsLiveInfo");
        con.addControl(this.imageBox);
        con.addControl(this.contents);
        this.panel.addControl(con);
        this.panel.addControl(this.isLiveInfo);
        this.bindEvents();
    }
    getFocusable() {
        return this.panel;
    }
    bindEvents () {
        this.panel.bind('click', () => {
            if (ignoreClickFix) return;
            if (this.stopClickPropogation) return;
            Router.showTeamScreen(this.data.id);
        });
        var startY = 0;
        var startX = 0;
        var ignoreClickFix = false;
        this.panel.bind("touchstart", e => {
            startY = e.originalEvent.touches[0].clientY;
            startX = e.originalEvent.touches[0].clientX;
            this.longPressHandler = setTimeout(() => {
                this.listRender.triggerEvent("requestActivateDrag", {
                    type: "team",
                    data: this.data
                });
            }, 500);
            this.panel.bind("touchmove", e => {
                if (Math.abs(startY - e.originalEvent.touches[0].clientY) > 10) {
                    clearTimeout(this.longPressHandler);
                    this.panel.unbind("touchmove", e.handleObj.handler);
                    ignoreClickFix = true;
                }
                if (Math.abs(startX - e.originalEvent.touches[0].clientX) > 10) {
                    clearTimeout(this.longPressHandler);
                    this.panel.unbind("touchmove", e.handleObj.handler);
                    ignoreClickFix = true;
                }
            });
        });
        this.panel.bind("touchend", () => {
            clearTimeout(this.longPressHandler);
            ignoreClickFix = false;
        });
    }
    setData (data,ignoreImemediateRender,ignoreLiveStats) {
        super.setData(data);
        this.contents.clear();
        this.panel.setCss({
            "height": data.style.height + "px"
        });
        this.isLiveInfo.clear();
        this.imageBox.alt = data.name;

        if (AppInstance.getConfig().enabledFeatures.teamImages) {
            this.imageBox.setImage(AppInstance.getConfig().url.imageBaseUrl + "/footapi/" + AppInstance.getConfig().url.teamPlayerImageUrl + "/teams_gs/" + this.data.id + "_small_rnd.png", 40, 40);
        }
        else{
            this.imageBox.setImage(AppInstance.getConfig().url.imageBaseUrl + "/footapi/" + AppInstance.getConfig().url.teamPlayerImageUrl + "/teams_gs/country/" + this.data.id + "_small_rnd.png", 40, 40);
        }
        this.contents.addControl(this.data.name);
        this.invalidateDisplay();

        if (ignoreLiveStats) return;

        LiveStatsHelper.getTeamMatchInfo(this.data.id, (data) => {
            this.renderLiveStats(data);
        });

    }

    renderLiveStats (data){
        if (this.isunloaded || data == null) return;

        var windowWidth = $(window).width();

        var isLive = false;
        var liveClass = "";
        if (MatchUtil.isPlaying(data)) {
            //this.isLiveInfo.addCssClass("live");
            isLive = true;
            liveClass = "live";
        }
        var sparts = data.scoretime.split("-");
        var content = "<table class='"+liveClass+"' style=text-align:end;width:"+windowWidth*0.45+"px;>"
        content += "<tr>";
        content += "<td class='listViewFavoriteIsLiveInfoStatus "+liveClass+"' colspan='2' style=''>";
        if (isLive) {
            content += "<i>";
            content += Localizer.getString("nowlive");
            content += " - "+MatchUtil.getShortStatus(data);
            content += "</i>";
        }
        else if (data.playedToday){
            content += "<i>";
            content += Localizer.getString("today");
            if (MatchUtil.isFinished(data)) {
                content += " - " + MatchUtil.getFullStatus(data);
            }
            else{
                content += " - " + MatchUtil.getLocalizedScoreTime(data);
            }
            content += "</i>";
        }
        else {
            content += "<i>";
            var dt = new DateTimecode();
            dt.setTimecode(MatchUtil.getTimecode(data));
            content += dt.toLocaleDateString();
            content += "</i>";
        }
        content += "</td>";
        content += "</tr>";

        content += "<tr>";
        content += "<td class='isLiveTeamInfoTeamName' style=''>";
        content += data.localteam;
        content += "</td>";

        if (MatchUtil.isValidScore(data)) {
            content += "<td style='max-width:12px; width:12px;'>";
            content += sparts[0] + "";
            content += "</td>";
        }
        content += "</tr>";
        content += "<tr>";
        content += "<td class='isLiveTeamInfoTeamName' style=''>";
        content += data.visitorteam;
        content += "</td>";
        if (MatchUtil.isValidScore(data)) {
            content += "<td style='max-width:12px; width:12px;'>";
            content += sparts[1] + "";
            content += "</td>";
        }
        content += "</tr>";

        content += "</table>";

        this.isLiveInfo.clear();
        this.isLiveInfo.bind("click", (e) => {
            e.stopPropagation();
            if (this.stopClickPropogation) return;
            console.log("show match screen");
           Router.showMatchScreen(null,data);
        });
        this.isLiveInfo.addControl(content);

        this.isLiveInfo.invalidateDisplay();
    }
};


export class SpecialItemItemRender extends  FocusableItemRender{
    init () {
        super.init();
        this.panel = new Link();
        this.panel.addCssClass("listView");
        this.panel.addCssClass("flexHorizontalAlign flexAlignStart listViewItemGeneral");
        this.panel.setStyle("cursor:pointer;");
        this.addControl(this.panel);
        this.imageBox = new ImagePlaceHolder();
        this.imageBox.addCssClass("listViewCountryFlagItem");
        this.imageBox.placeholderData = countryPlaceHolder;
        this.contents = new Panel();
        this.contents.addCssClass("listViewCountryTitle");
        this.panel.addControl(this.imageBox);
        this.panel.addControl(this.contents);
        this.panel.bind('click', () => {

            if (this.data.item == "news") {
                Router.showNews();
            } else if (this.data.item == "fifaranking") {
                Router.showFifaRanking();
            } else if (this.data.item == "playervalues") {
                Router.showPlayerMarketValues();
            } else if (this.data.item == "forumindex") {
                Router.showForumIndex();
            } else if (this.data.item == "topvoters") {
                Router.showTopVoters();
            } else if (this.data.item == "euro2024") {
                //Router.showTopVoters();
                Router.showLeagueScreen({
                    "key" : "EurocupsEuropeanChampionship"
                });
            }

            console.log(this.data);

            //console.log("click");
            //Router.showSettings();
            //this.getPage().getApplication().loadPage("PlayerDetail",{'id':this.data.id_gs});
        });
    }

    getFocusable() {
        return this.panel;
    }

    setData (data) {
        this.data = data;
        this.contents.clear();
        this.panel.setCss({
            "height": data.style.height + "px"
        });
        this.imageBox.alt = data.title;
        this.imageBox.setImage(AppInstance.getConfig().url.imageBaseUrl + "/footapi/images/flags/" + data.image.replaceAll(" ", "-").toLowerCase(), 40, 40);
        this.contents.addControl(data.title);
        this.invalidateDisplay();
    }
};
export class AgendaItemRender extends FocusableItemRender{
    init () {
        super.init();
        this.panel = new Panel();
        this.panel.addCssClass("listView");
        this.panel.addCssClass("flexHorizontalAlign flexAlignStart listViewItemGeneral");
        this.addControl(this.panel);

        this.contents = new Panel();
        this.contents.addCssClass("listViewAgenda");
        this.panel.addControl(this.contents);
    }
    updateDisplay () {
        $("#" + this.panel.getClientId()).on("touchstart", function (e) {
            e.stopPropagation();
        });
        this.createAgendaItems();
    }
    focus() {
        var r = super.focus();
        this.currentFocusableIndex = this.defaultFocusableIndex;
        return r;
    }

    focusNext (isTabKey,isLeftRight,isUpDown) {
        if (isTabKey || isUpDown) return false;
        var r= super.focusNext(isTabKey,isLeftRight,isUpDown);
        if (r) {
            this.currentFocusableIndex++;
        }
        return r;
    }
    focusPrevious (isTabKey,isLeftRight,isUpDown) {
        if (isTabKey || isUpDown) return false;
        var r = super.focusPrevious(isTabKey,isLeftRight,isUpDown);
        if (r) {
            this.currentFocusableIndex--;
        }
        return r;
    }
    getFocusable() {
        return this.todayElement;
    }
    getNextFocusable() {
        if (!this.contents.controls[this.currentFocusableIndex+1]) return false;
        return this.contents.controls[this.currentFocusableIndex+1];
    }
    getPreviousFocusable() {
        if (!this.contents.controls[this.currentFocusableIndex-1]) return false;
        return this.contents.controls[this.currentFocusableIndex-1];
    }
    unload () {
        $("#" + this.panel.getClientId()).off("touchstart");
    }
    setData (data) {
        this.data = data;
    }
    createAgendaItems () {
        //alert("create agenda items");
        var screenWidth = this.getPage().getDimensions().width;
        if (!screenWidth) return;
        this.contents.clear();
        var itemWidth = screenWidth / 7;
        var totalItems = 21;
        //var totalItems = 7;

        var d = new Date();
        d.setMinutes(0);
        d.setHours(0);
        d.setSeconds(0);
        d.setMilliseconds(0);
        var startTC = d.getTime() - 1000 * (60 * 60 * 24) * Math.floor(totalItems / 2);
        var dot = new Date();
        dot.setTime(startTC);
        var formatter1 = Intl.DateTimeFormat(FootballManiaConfig.userLocale, {
            weekday: 'short'
        });
        var formatter2 = Intl.DateTimeFormat(FootballManiaConfig.userLocale, {
            month: 'short',
            day: 'numeric'
        });
        var now = formatter2.format(new Date());
        for (var i = 0; i < totalItems; i++) {
            var agendaItemContainer = new Link();
            //agendaItemContainer.setAttribute("tabindex", "-1");
            agendaItemContainer.setStyle("width:" + itemWidth + "px;");
            (function (tc) {
                tc = new Date(tc);
                agendaItemContainer.bind("click", function () {
                    //var date = Intl.DateTimeFormat('en-UK').format(tc);
                    var date = ('0' + tc.getDate()).slice(-2) + '/'
                    + ('0' + (tc.getMonth()+1)).slice(-2) + '/'
                    + tc.getFullYear()

                    Router.showAgendaScreen(date.replaceAll("/", "-"));
                });
            })(dot.getTime());
            var day = formatter1.format(dot);
            var date = formatter2.format(dot);
            if (date == now) {
                this.todayElement = agendaItemContainer;
                day = Localizer.getString("today");
                this.currentFocusableIndex = i;
                this.defaultFocusableIndex = i;
            }
            agendaItemContainer.addControl("<div>" + day + "</div>");
            agendaItemContainer.addControl("<div>" + date + "</div>");
            dot.setDate(dot.getDate() + 1);
            this.contents.addControl(agendaItemContainer);
        }
        this.contents.invalidateDisplay();
        var scroll = jQuery("#" + this.contents.getClientId());
        var scrollToToday = 1 * (scroll[0].scrollWidth - screenWidth + screenWidth / 6) / 2;
        if (FootballManiaConfig.isRtl) {
            scrollToToday *= -1;
        }
        jQuery("#" + this.contents.getClientId()).scrollLeft(scrollToToday);
    }
};

export class MatchSuggestionsItemRender extends  DataGridTileItemRender{
    init () {

        super.init();
        this.panel = new Panel();
        this.panel.addCssClass("listView");
        this.panel.addCssClass("flexHorizontalAlign flexAlignStart listViewItemGeneral");
        this.addControl(this.panel);
        this.contents = new Panel();
        this.contents.addCssClass("listViewMatchSuggestions");
        this.panel.addControl(this.contents);
    }
    updateDisplay () {
        $("#" + this.panel.getClientId()).on("touchstart", function (e) {
            e.stopPropagation();
        });
        this.createItems();
    }
    unload () {
        $("#" + this.panel.getClientId()).off("touchstart");
    }
    setData (data) {
        this.data = data;
        this.isDone = false;
        console.log("setData",data);
        this.createItems();
    }
    createItems () {
        if (!this.data){
            console.warn("no data to create suggestions list");
            return;
        }
        if (this.isDone){
            console.warn("already created suggestions list");
            return;
        }
        //alert("create agenda items");
        //if (this.isRendered) return;


        var screenWidth = this.getPage().getDimensions().width;
        if (!screenWidth) return;
        this.contents.clear();

        var itemCount = 2;
        var additionalItems = Math.round((screenWidth / itemCount)/80)-1;
        console.log("additionalItems",additionalItems);
        itemCount +=additionalItems;

        var itemWidth = screenWidth / itemCount;
        //console.log(itemWidth/80);


        console.log("createAgendaItems",this.data,this.isRendered);

        var totalItems = this.data.matchSuggestions.matches.length;
        //var totalItems = 7;

        console.log(this.data);
        var size =Math.min(42,(itemWidth / 2) - (itemWidth / 10));
        for (var i = 0; i < totalItems; i++) {
            console.log("match item",this.data.matchSuggestions.matches[i]);
            var agendaItemContainer = new Panel();
            agendaItemContainer.setStyle("width:" + itemWidth + "px;");

            this.contents.addControl(agendaItemContainer);

            this.createMatchItem(agendaItemContainer,this.data.matchSuggestions.matches[i],size);
        }
        this.contents.invalidateDisplay();
        var scroll = jQuery("#" + this.contents.getClientId());
        var scrollToToday = 1 * (scroll[0].scrollWidth - screenWidth + screenWidth / 6) / 2;
        if (FootballManiaConfig.isRtl) {
            scrollToToday *= -1;
        }
        jQuery("#" + this.contents.getClientId()).scrollLeft(scrollToToday);
        this.isDone = true;
    }

    createMatchItem (container,match,size) {
        var panel = new Panel();
        panel.addCssClass("listViewMatchSuggestionsMatchItem");
        container.addControl(panel);

        //match.id = match.matchid;
        panel.bind("click",  ()=> {
            Router.showMatchScreen(null,match);
        });

        var topContainer = new Panel();
        topContainer.addCssClass("listViewMatchSuggestionsMatchItemTop");
        var middleContainer = new Panel();
        middleContainer.addCssClass("listViewMatchSuggestionsMatchItemMiddle");
        var bottomContainer = new Panel();
        bottomContainer.addCssClass("listViewMatchSuggestionsMatchItemBottom");
        panel.addControl(topContainer);
        panel.addControl(middleContainer);
        panel.addControl(bottomContainer);

        var homeImage = new ImagePlaceHolder();
        homeImage.addCssClass("listViewMatchSuggestionsMatchItemHomeImage");
        homeImage.setStyle("width:" + size + "px;height:" + size + "px;");
        homeImage.setImage(AppInstance.getConfig().url.imageBaseUrl + "/footapi/" + AppInstance.getConfig().url.teamPlayerImageUrl + "/teams_gs/" + match.gs_localteamid + "_small_rnd.png", size, size);
        topContainer.addControl(homeImage);

        var awayImage = new ImagePlaceHolder();
        awayImage.addCssClass("listViewMatchSuggestionsMatchItemAwayImage");
        awayImage.setStyle("width:" + size + "px;height:" + size + "px;");
        awayImage.setImage(AppInstance.getConfig().url.imageBaseUrl + "/footapi/" + AppInstance.getConfig().url.teamPlayerImageUrl + "/teams_gs/" + match.gs_visitorteamid + "_small_rnd.png", size, size);
        topContainer.addControl(awayImage);

        if (MatchUtil.isPenalty(match)) {
            //middleContainer.addControl(MatchUtil.getShortStatus(match));
            //bottomContainer.addControl(MatchUtil.getLocalizedPenalty(match,false,true));
            middleContainer.addControl(MatchUtil.getLocalizedPenalty(match,false,true));
        }
        else if (MatchUtil.isFinished(match) || MatchUtil.isPlaying(match)) {
            //middleContainer.addControl(MatchUtil.getShortStatus(match));
            middleContainer.addControl(MatchUtil.getLocalizedScoreTime(match));
        }
        else {
            middleContainer.addControl(MatchUtil.getLocalizedDate(match,false,true));
            //bottomContainer.addControl(MatchUtil.getLocalizedPenalty(match,false,true));
        }

        if (MatchUtil.isPlaying(match)) {
            //add in green text
            bottomContainer.addControl("<p class='greentext'>"+Localizer.getString("live")+"</p>");
        }
        else if (MatchUtil.isFinished(match)) {
            //add in red text
            bottomContainer.addControl(Localizer.getString("finaltime_short"));
        }

    }
};
export class HeaderTeamFavoriteItemRender extends FocusableItemRender{
    init () {
        super.init();
        this.panel = new Link();
        this.panel.addCssClass("listViewHeader");
        this.panel.setStyle("justify-content: space-between;padding-inline-end:0;");
        this.addControl(this.panel);
        this.contents = new Panel();
        this.addButton = new Panel();
        this.addButton.setStyle("right:2px;height:28px;width:28px;");
        this.addButton.addCssClass("icon-add-favorite");
        //this.addButton.setVisible(window.showTeamAddFavoriteButton ? true : false);
        this.addButton.bind('click', () => {
            this.action();
        });
        this.panel.addControl(this.contents);

        if (this.addButtonEnabled()) {
            this.panel.addControl(this.addButton);
        }
    }
    getFocusable() {
        return this.panel;
    }
    setData (data) {
        super.setData(data);
        this.contents.clear();
        this.panel.setCss({
            "height": data.style.height + "px"
        });
        this.contents.addControl(this.data.title);
        this.contents.invalidateDisplay();
    }
    addButtonEnabled  (enabled) {
        return Favorites.hasFavoriteTeams();
    }
    action () {
        var urlIdentifier = "teamSelectModal";
        var pan = new FavoriteTeamAdderModal(urlIdentifier);
        this.modal = pan;
        this.getPage().addControl(pan, true);
    }
};
export class HeaderLeagueFavoriteItemRender extends  HeaderTeamFavoriteItemRender{
    action () {
        var urlIdentifier = "leagueSelectModal";
        var pan = new FavoriteLeagueAdderModal(urlIdentifier);
        this.modal = pan;
        this.getPage().addControl(pan, true);
    }
    addButtonEnabled  (enabled) {
        return Favorites.hasFavoriteLeagues();
    }
};
export class HeaderItemRender extends FocusableItemRender{
    init () {
        super.init();
        this.panel = new Link();
        this.panel.addCssClass("listViewHeader");
        this.addControl(this.panel);
        this.contents = new Panel();
        this.panel.addControl(this.contents);
    }
    getFocusable() {
        return this.panel;
    }
    setData (data) {
        super.setData(data);
        this.contents.clear();
        this.panel.setCss({
            "height": data.style.height + "px"
        });
        this.contents.addControl(this.data.title);
        this.invalidateDisplay();
    }
};
export class CountryItemRender extends FocusableItemRender{
    init () {
        super.init();
        this.panel = new Link();
        this.panel.addCssClass("listViewItem");
        this.addControl(this.panel);
        this.imageBox = new ImagePlaceHolder();
        this.imageBox.addCssClass("listViewCountryFlagItem");
        this.imageBox.placeholderData = countryPlaceHolder;
        this.contents = new Panel();
        this.contents.addCssClass("listViewCountryTitle");
        this.panel.addControl(this.imageBox);
        this.panel.addControl(this.contents);

        this.panel.bind('click', () => {
            console.log("click", this.data);
            Router.showCountryLeaguesScreen(this.data);
        });
    }
    getFocusable() {
        return this.panel;
    }

    setData (data, preventInstantRender) {
        this.data = data;
        this.contents.clear();
        this.panel.setCss({
            "height": data.style.height + "px"
        });
        this.imageBox.alt = data.countryLocalized;
        this.imageBox.setImage(AppInstance.getConfig().url.imageBaseUrl + "/footapi/images/flags/" + data.country.replaceAll(" ", "-").toLowerCase() + ".png", 40, 40);
        this.contents.addControl(data.countryLocalized);
        this.invalidateDisplay();
    }
};
export class MatchHeaderDateRender extends  FocusableItemRender{
    getFocusable() {
        return this.panel;
    }

    init () {
        super.init();
        this.panel = new Link();
        this.panel.addCssClass("flexHorizontalAlign listViewHeader listViewHeaderMatchDateHeader");
        this.panel.setStyle("height:40px;");
        this.addControl(this.panel);
    }
    setData (data, preventRender, preventInstantRender) {
        //this._super(data);


        this.panel.clear();
        var d = Intl.DateTimeFormat(FootballManiaConfig.userLocale, {
            weekday: 'long',
            year: 'numeric',
            month: 'long',
            day: 'numeric'
        }).format(data.dayTimecodeLocalized);
        //this.panel.addControl(MatchUtil.getLocalizedDate(data,true),true);
        this.panel.addControl("<div>" + d + " </div>");
        //console.log("MatchHeaderDateRender", data);
        if (data.week) {
            var stage = "";
            if (data.stageName != "Regular Season") {
                stage = data.stageName ? " - " + data.stageName : "";
            }
            this.panel.addControl("<div style='font-size:12px;margin-inline-end: 4px;'>" + Localizer.getString("round") + " " + data.week +stage+"</div>");
            ///this.panel.addControl(data.stageName);
        } else if (data.stage) {
            var state = data.stage.split("-");
            if (state.length > 2) state = state[1];else state = data.stage;
            this.panel.addControl("<div style='font-size:12px;margin-inline-end: 4px;'>" + state + "</div>");
        } else if (data.stageRound) { //data.stageRound (group stage) l data.stageName (group B)
            var state = data.stage.split("-");
            if (state.length > 2) state = state[1];else state = data.stageRound;
            this.panel.addControl("<div style='font-size:12px;margin-inline-end: 4px;'>" + state + "</div>");
        }
        if (this.isRendered) {
            this.panel.invalidateDisplay();
        } else {
            this.invalidateDisplay();
        }
    }
};
export class MatchHeaderItemRender extends  FocusableItemRender{

    getFocusable() {
        return this.panel;
    }

    init () {
        super.init();
        this.panel = new Link();
        this.panel.addCssClass("listViewMatchHeader");
        this.addControl(this.panel);

        this.imageBox = new Image();
        this.imageBox.addCssClass("listViewMatchHeaderCountryImage");
        this.imageBox.setStyle("width:40px;height:40px;");
        //this.imageBox.placeholderData = countryPlaceHolder;

        this.contents = new Panel();
        this.contents.addCssClass("listViewMatchHeaderCountryTitle");
        this.favoriteContainer = new Panel();
        this.favoriteContainer.addCssClass("listViewMatchHeaderFavoriteContainer");
        if (FootballManiaConfig.isRtl) {
            this.favoriteContainer.setStyle("margin-right:auto;");
        }
        else{
            this.favoriteContainer.setStyle("margin-left:auto;");
        }
        this.favoriteLeague = new Panel();
        this.favoriteLeague.addCssClass("icon-favorite-off");
        this.favoriteLeague.addCssClass("menu-icon");
        this.panel.bind('click', () => {
            Router.showLeagueScreen(this.data);
        });
        this.favoriteLeague.bind("click", e => {
            e.stopImmediatePropagation();
            if (!this.data.isFavoriteLeague) {
                this.data.isFavoriteLeague = true;
                this.favoriteLeague.removeCssClass("icon-favorite-off");
                this.favoriteLeague.addCssClass("icon-favorite-on");
                Favorites.addFavoriteLeague(this.data.key, this.data.country, this.data.league);
            } else {
                this.data.isFavoriteLeague = false;
                this.favoriteLeague.removeCssClass("icon-favorite-on");
                this.favoriteLeague.addCssClass("icon-favorite-off");
                Favorites.removeFavoriteLeague(this.data.key, this.data.country, this.data.league);
            }
        });
        this.panel.addControl(this.imageBox);
        this.panel.addControl(this.contents);
        this.panel.addControl(this.favoriteContainer);
        this.favoriteContainer.addControl(this.favoriteLeague);
    }
    setData (data, preventRender, preventInstantRender) {
        //return;
        this.data = data;
        this.contents.clear();
        this.panel.setCss({
            "height": data.style.height + "px"
        });
        data.title = RemoveReservedIfNeeded(data.title);
        this.contents.addControl(data.title, true);
        this.imageBox.setAttribute("alt", data.title);
        console.log("------------",data);

        data.country = Remapper.remapCountryLogo(data.key,data.country);

        this.imageBox.setImage(AppInstance.getConfig().url.imageBaseUrl + "/footapi/images/flags/" + data.country.replaceAll(" ", "-").toLowerCase() + ".png", 30, 30);

        //this.favoriteLeague.unbind();
        if (data.isFavoriteLeague) {
            this.favoriteLeague.removeCssClass("icon-favorite-off");
            this.favoriteLeague.addCssClass("icon-favorite-on");
        } else {
            this.favoriteLeague.removeCssClass("icon-favorite-on");
            this.favoriteLeague.addCssClass("icon-favorite-off");
        }
        if (this.isRendered) {
            //this.contentPanel.invalidateDisplay();
        } else {
            this.invalidateDisplay();
        }
    }
};
export class MatchItemRender extends FocusableItemRender{

    getFocusable() {
        return this.panel;
    }

    init (hideBell,renderVariant, showWhenFavorite) {
        super.init();
        this.renderVariant = renderVariant;
        this.hideBell = hideBell;
        this.lastImageLoad = -1;
        this.rendered = false;
        this.showWhenFavorite = showWhenFavorite;

        this.panel = new Link();
        this.panel.addCssClass("listViewItem");
        this.addControl(this.panel);

        if (this.renderVariant == undefined){
            if (ManiaPreferences.getMatchListType() == "2"){
                this.renderVariant = 2;
            }
            else if (ManiaPreferences.getMatchListType() == "3"){
                this.renderVariant = 3;
            }
            else{
                this.renderVariant = 1;
            }
        }

        if (this.renderVariant == 1 ) {

            this.notificationContainer = new Panel();
            this.notificationContainer.addCssClass("listViewMatchNotificationContainer");
            this.notificationBell = new Panel();
            this.notificationBell.addCssClass("listViewMatchNotificationBell");
            this.notificationBell.addCssClass("icon-bell");

            this.contentPanel = new Panel();
            this.contentPanel.addCssClass("listViewMatchContentContainer");
            this.homePanelContainer = new Panel();
            this.homePanelContainer.addCssClass("listViewMatchHomeContainer");
            this.awayPanelContainer = new Panel();
            this.awayPanelContainer.addCssClass("listViewMatchAwayContainer");
            this.homeImage = new ImagePlaceHolder();
            this.homeImage.addCssClass("listViewMatchHomeTeamLogoContainer");
            this.homeImage.image.addCssClass("listViewMatchTeamLogoImage");
            this.homeImage.placeHolder.addCssClass("listViewMatchTeamLogoPlaceHolder");
            this.homeImage.placeholderData = teamPlaceholder;
            this.awayImage = new ImagePlaceHolder();
            this.awayImage.addCssClass("listViewMatchAwayTeamLogoContainer");
            this.awayImage.image.addCssClass("listViewMatchTeamLogoImage");
            this.awayImage.placeHolder.addCssClass("listViewMatchTeamLogoPlaceHolder");
            this.awayImage.placeholderData = teamPlaceholder;
            this.homeRedcard = new Panel();
            this.homeRedcard.addCssClass("listViewMatchRedCardHome");
            this.homeImage.addControl(this.homeRedcard);
            this.awayRedcard = new Panel();
            this.awayRedcard.addCssClass("listViewMatchRedCardAway");
            this.awayImage.addControl(this.awayRedcard);
            this.status = new Panel();
            this.status.addCssClass("listViewMatchStatusContainer");
            this.statusAggr = new Panel();
            this.statusAggr.addCssClass("listViewMatchStatusAggr");
            this.statusTime = new Panel();
            this.statusTime.addCssClass("listViewMatchStatusScoreTime");
            this.statusMatch = new Panel();
            this.statusMatch.addCssClass("listViewMatchStatusMatch");
            this.hometeam = new Panel();
            this.hometeam.setStyle("");
            this.hometeam.addCssClass("listViewMatchHomeTitle");
            this.awayteam = new Panel();
            this.awayteam.setStyle("");
            this.awayteam.addCssClass("listViewMatchAwayTitle");
            if (!this.hideBell) {
                this.notificationContainer.addControl(this.notificationBell);
                this.panel.addControl(this.notificationContainer);
            }
            //this.arrowContainer.addControl(this.arrow);
            this.panel.addControl(this.arrowContainer);
            this.panel.addControl(this.contentPanel);
            this.homePanelContainer.addControl(this.hometeam);
            this.homePanelContainer.addControl(this.homeImage);
            this.awayPanelContainer.addControl(this.awayImage);
            this.awayPanelContainer.addControl(this.awayteam);
            this.contentPanel.addControl(this.homePanelContainer);
            this.contentPanel.addControl(this.status);
            this.contentPanel.addControl(this.awayPanelContainer);
            this.status.addControl(this.statusAggr);
            this.status.addControl(this.statusTime);
            this.status.addControl(this.statusMatch);
        }
        else{

            this.notificationContainer = new Panel();
            this.notificationContainer.addCssClass("listViewMatchNotificationContainer");
            this.notificationContainer.addCssClass("listViewMatchNotificationContainerAlt");
            this.notificationBell = new Panel();
            this.notificationBell.addCssClass("listViewMatchNotificationBell");
            this.notificationBell.addCssClass("icon-bell");

            this.left = new Panel();
            this.left.addCssClass("listViewMatchLeft");
            this.right = new Panel();
            this.right.addCssClass("listViewMatchRight");


            this.contentPanel = new Panel();
            this.contentPanel.addCssClass("listViewMatchContentContainerAlt");

            this.teamsContainer = new Panel();
            this.teamsContainer.addCssClass("listViewMatchTeamsContainer");
            this.scoreContainer = new Panel();
            this.scoreContainer.addCssClass("listViewMatchScoreContainer");
            this.cardContainer = new Panel();
            this.cardContainer.addCssClass("listViewMatchScoreContainer");

            this.homeRedcard = new Panel();
            this.homeRedcard.addCssClass("listViewMatchRedCardAlt");
            this.awayRedcard = new Panel();
            this.awayRedcard.addCssClass("listViewMatchRedCardAlt");


            this.scoreLocal = new Panel();
            this.scoreAway = new Panel();

            this.homePanelContainer = new Panel();
            this.homePanelContainer.addCssClass("listViewMatchHomeContainer");
            this.awayPanelContainer = new Panel();
            this.awayPanelContainer.addCssClass("listViewMatchAwayContainer");

            this.homeImage = new ImagePlaceHolder();
            this.homeImage.addCssClass("listViewMatchTeamLogoContainerAlt");
            this.homeImage.placeholderData = teamPlaceholder;
            this.awayImage = new ImagePlaceHolder();
            this.awayImage.addCssClass("listViewMatchTeamLogoContainerAlt");
            this.awayImage.placeholderData = teamPlaceholder;

            this.status = new Panel();
            this.status.addCssClass("listViewMatchStatusContainerAlt");
            this.images = new Panel();
            this.images.addCssClass("listViewMatchImageContainerAlt");
            this.statusAggr = new Panel();
            this.statusAggr.addCssClass("listViewMatchStatusAggr");
            this.statusTime = new Panel();
            this.statusTime.addCssClass("listViewMatchStatusScoreTime");
            this.statusMatch = new Panel();
            this.statusMatch.addCssClass("listViewMatchStatusMatch listViewMatchStatusMatchAlt");
            this.hometeam = new Panel();
            this.hometeam.setStyle("");
            this.hometeam.addCssClass("listViewMatchTeamTitleAlt");
            this.awayteam = new Panel();
            this.awayteam.setStyle("");
            this.awayteam.addCssClass("listViewMatchTeamTitleAlt");
            //this.arrowContainer.addControl(this.arrow);

            //this.panel.addControl(this.arrowContainer);
            this.panel.addControl(this.contentPanel);
            this.contentPanel.addControl(this.left);
            this.contentPanel.addControl(this.right);

            this.left.addControl(this.status);

            if (this.renderVariant == 3) {
                this.left.addControl(this.images);
                this.images.addControl(this.homeImage);
                this.images.addControl(this.awayImage);
            }

            this.left.addControl(this.teamsContainer);
            this.teamsContainer.addControl(this.hometeam);
            this.teamsContainer.addControl(this.awayteam);

            this.cardContainer.addControl(this.homeRedcard);
            this.cardContainer.addControl(this.awayRedcard);

            this.scoreContainer.addControl(this.scoreLocal);
            this.scoreContainer.addControl(this.scoreAway);
            this.right.addControl(this.cardContainer);
            this.right.addControl(this.scoreContainer);

            if (!this.hideBell) {
                this.notificationContainer.addControl(this.notificationBell);
                this.right.addControl(this.notificationContainer);
            }

            this.status.addControl(this.statusAggr);
            this.status.addControl(this.statusMatch);
        }


        this.panel.bind('click', e => {
            if (this.data.match_file_exist == 0) {
                console.warn("no match file exists for this match ", this.data.id);
                return;
            }
            e.stopPropagation();
            e.stopImmediatePropagation();
            Router.showMatchScreen(this.getPage(), this.data);
        });

        if (!AppInstance.isOnline()) {
            this.notificationBell.addCssClass("icon-bell-disabled");
        }

        this.notificationContainer.bind("click", e => {

            e.stopPropagation();
            e.preventDefault();
            e.stopImmediatePropagation();

            if (!AppInstance.isOnline()) {
                return;
            }

            if (MatchUtil.isFinished(this.data)) {
                console.log("match already finished. do nothing");
                return;
            }
            if (Subscriber.hasMatchSubscription(this.data.id)) {
                this.notificationBell.removeCssClass("icon-bell-active")
                this.notificationBell.addCssClass("icon-bell-inactive");
                Subscriber.unsubscribeMatch(this.data.id, (result) => {
                    if (!result) {
                        this.notificationBell.removeCssClass("icon-bell-inactive");
                        this.notificationBell.addCssClass("icon-bell-error");
                        setTimeout(() => {
                            this.notificationBell.removeCssClass("icon-bell-error");
                            this.notificationBell.addCssClass("icon-bell-active");
                        }, 800)
                    }
                });
            } else {
                this.notificationBell.removeCssClass("icon-bell-inactive")
                this.notificationBell.addCssClass("icon-bell-active");

                Subscriber.subscribeMatch(this.data.id, (result) => {
                    if (!result) {
                        this.notificationBell.removeCssClass("icon-bell-active");
                        this.notificationBell.addCssClass("icon-bell-error");
                        setTimeout(() => {
                            this.notificationBell.removeCssClass("icon-bell-error");
                            this.notificationBell.addCssClass("icon-bell-inactive");
                        }, 800)
                    }
                });
            }
        });

    };
    setData (data, preventRender, preventInstantRender) {


        super.setData(data);
        if (preventRender) return;

        if (this.cachedHeight != data.style.height) {
            this.panel.setCss({
                "height": data.style.height + "px"
            });
        }
        this.cachedHeight = data.style.height;

        if (AppInstance.isOnline()) {
            this.notificationBell.removeCssClass("icon-bell-active");
            this.notificationBell.removeCssClass("icon-bell-disabled");
            this.notificationBell.removeCssClass("icon-bell-inactive");
            if (MatchUtil.isFinished(this.data)) {
                this.notificationBell.addCssClass("icon-bell-disabled");
            } else if (Subscriber.hasMatchSubscription(this.data.id)) {
                this.notificationBell.addCssClass("icon-bell-active");
            } else {
                this.notificationBell.addCssClass("icon-bell-inactive");
            }
        }


        if (this.renderVariant == 1) {

            this.hometeam.clear();
            this.statusTime.clear();
            this.awayteam.clear();

            this.statusAggr.clear();
            this.statusMatch.clear();
            this.hometeam.addControl(this.data.localteam);
            this.awayteam.addControl(this.data.visitorteam);

            if (this.showWhenFavorite) {
                if (Favorites.isFavoriteTeam(this.data.gs_localteamid)) {
                    this.hometeam.addControl("<p class='icon-favorite-on listViewTeamFavoriteStar'></p>");
                }
                if (Favorites.isFavoriteTeam(this.data.gs_visitorteamid)) {
                    this.awayteam.addControl("<p class='icon-favorite-on listViewTeamFavoriteStar'></p>");
                }
            }

            this.statusMatch.removeCssClass("green");
            this.statusMatch.removeCssClass("yellow");
            this.statusMatch.removeCssClass("red");

            //console.log(this.data.localteam,this.data);

            //we cache the status to avoid re-processing during scrolling
            if (!this.data.cachedStatus) {
                this.data.cachedStatus = {};
                var penaltyLive = false;
                if (MatchUtil.isLivePenalty(this.data)) {
                    penaltyLive = true;
                    this.data.cachedStatus.statusMatchCss = "green";
                    this.data.cachedStatus.statusAggr = MatchUtil.getLocalizedPenalty(this.data);
                    this.data.cachedStatus.statusMatch = MatchUtil.getShortStatus(this.data);
                } else if (MatchUtil.isPenalty(this.data)) {
                    penaltyLive = true;
                    this.data.cachedStatus.statusMatch = MatchUtil.getLocalizedPenalty(this.data);
                } else if (MatchUtil.isPlaying(this.data)) {
                    this.data.cachedStatus.statusMatch = MatchUtil.getShortStatus(this.data);
                    this.data.cachedStatus.statusMatchCss = "green";
                } else if (MatchUtil.isHalftime(this.data)) {
                    this.data.cachedStatus.statusMatch = MatchUtil.getShortStatus(this.data);
                    this.data.cachedStatus.statusMatchCss = "green";
                } else if (MatchUtil.isFinished(this.data)) {
                    // this.statusMatch.addControl(MatchUtil.getShortStatus(this.data));
                } else if (MatchUtil.isPostponed(this.data)) {
                    this.data.cachedStatus.statusMatchCss = "red";
                    this.data.cachedStatus.statusMatch = MatchUtil.getShortStatus(this.data);
                    // holder1.statusgreen.setVisibility(View.VISIBLE);
                    // holder1.statusgreen.setTextColor(Color.parseColor("#F58282"));
                    // holder1.statusgreen.setTextSize(TypedValue.COMPLEX_UNIT_SP, 12);
                    // holder1.statusgreen.setText(mContext.getResources().getString(com.holoduke.football_live.base.R.string.postponed_short));
                } else if (MatchUtil.isInterrupted(this.data)) {
                    this.data.cachedStatus.statusMatchCss = "yellow";
                    this.data.cachedStatus.statusMatch = MatchUtil.getShortStatus(this.data);
                } else if (MatchUtil.isAbandoned(this.data)) {
                    this.data.cachedStatus.statusMatchCss = "yellow";
                    this.data.cachedStatus.statusMatch = MatchUtil.getShortStatus(this.data);
                } else if (MatchUtil.isCancelled(this.data)) {
                    this.data.cachedStatus.statusMatchCss = "yellow";
                    this.data.cachedStatus.statusMatch = MatchUtil.getShortStatus(this.data);
                } else if (MatchUtil.isSuspended(this.data)) {
                    this.data.cachedStatus.statusMatchCss = "yellow";
                    this.data.cachedStatus.statusMatch = MatchUtil.getShortStatus(this.data);
                } else {
                    //console.log("no match for this status ", this.data.status);
                }
                if (!penaltyLive && this.data.aggregateScore != null) {
                    this.data.cachedStatus.statusAggr = MatchUtil.getLocalizedAggregateScore(this.data);
                }
            }
            if (this.data.cachedStatus) {
                if (this.data.cachedStatus.statusMatchCss) {
                    this.statusMatch.addCssClass(this.data.cachedStatus.statusMatchCss);
                }
                if (this.data.cachedStatus.statusAggr) {
                    this.statusAggr.addControl(this.data.cachedStatus.statusAggr);
                } else {
                    this.statusAggr.addControl("");
                }
                if (this.data.cachedStatus.statusMatch) {
                    this.statusMatch.addControl(this.data.cachedStatus.statusMatch);
                } else {
                    this.statusMatch.addControl("");
                }
            }

            //when user is fast scrolling we dont update all images classantly. too much of a performance hit
            if (this.listRender && this.listRender.isFastScrolling) {

                if (this.data.cachedLocalizedScoreTime) {
                    this.statusTime.addControl(this.data.cachedLocalizedScoreTime);
                }
                clearTimeout(this.backoffMethod);
                this.backoffMethod = setTimeout(() => {
                    if (this.unloaded) {
                        return console.warn("page is unloaded abort further actions");
                    }
                    this.usingPlaceholderTeamImage = false;
                    this.statusTime.clear();
                    this.data.cachedLocalizedScoreTime = MatchUtil.getLocalizedScoreTime(this.data);
                    this.statusTime.addControl(this.data.cachedLocalizedScoreTime, true);
                    this.homeImage.alt = this.data.localteam;
                    this.homeImage.setImage(AppInstance.getConfig().url.imageBaseUrl + "/footapi/" + AppInstance.getConfig().url.teamPlayerImageUrl + "/teams_gs/" + this.data.gs_localteamid + "_small_rnd.png", AppInstance.getConfig().sizes.fixtureListImageSize, AppInstance.getConfig().sizes.fixtureListImageSize);
                    this.awayImage.alt = this.data.visitorteam;
                    this.awayImage.setImage(AppInstance.getConfig().url.imageBaseUrl + "/footapi/" + AppInstance.getConfig().url.teamPlayerImageUrl + "/teams_gs/" + this.data.gs_visitorteamid + "_small_rnd.png", AppInstance.getConfig().sizes.fixtureListImageSize, AppInstance.getConfig().sizes.fixtureListImageSize);
                }, 300);
                if (!this.usingPlaceholderTeamImage) {
                    this.homeImage.showPlaceHolder();
                    this.awayImage.showPlaceHolder();
                }

                //this.usingPlaceholderTeamImage = true;
            } else {
                if (!this.data.cachedLocalizedScoreTime) {
                    this.data.cachedLocalizedScoreTime = MatchUtil.getLocalizedScoreTime(this.data);
                }
                this.statusTime.addControl(this.data.cachedLocalizedScoreTime);

                this.homeImage.alt = this.data.localteam;
                this.homeImage.setImage(AppInstance.getConfig().url.imageBaseUrl + "/footapi/" + AppInstance.getConfig().url.teamPlayerImageUrl + "/teams_gs/" + this.data.gs_localteamid + "_small_rnd.png", AppInstance.getConfig().sizes.fixtureListImageSize, AppInstance.getConfig().sizes.fixtureListImageSize);
                this.awayImage.alt = this.data.visitorteam;
                this.awayImage.setImage(AppInstance.getConfig().url.imageBaseUrl + "/footapi/" + AppInstance.getConfig().url.teamPlayerImageUrl + "/teams_gs/" + this.data.gs_visitorteamid + "_small_rnd.png", AppInstance.getConfig().sizes.fixtureListImageSize, AppInstance.getConfig().sizes.fixtureListImageSize);
             }
        }
        else{
            this.hometeam.clear();
            this.awayteam.clear();
            this.scoreLocal.clear();
            this.scoreAway.clear();
            this.statusMatch.clear();

            this.scoreLocal.addControl(MatchUtil.getLocalScore(this.data));
            this.scoreAway.addControl(MatchUtil.getAwayScore(this.data));

            this.hometeam.addControl(this.data.localteam);
            this.awayteam.addControl(this.data.visitorteam);

            if (this.showWhenFavorite) {
                if (Favorites.isFavoriteTeam(this.data.gs_localteamid)) {
                    this.hometeam.addControl("<p class='icon-favorite-on listViewTeamFavoriteStar'></p>");
                }
                if (Favorites.isFavoriteTeam(this.data.gs_visitorteamid)) {
                    this.awayteam.addControl("<p class='icon-favorite-on listViewTeamFavoriteStar'></p>");
                }
            }

            this.statusMatch.removeCssClass("green");
            this.statusMatch.removeCssClass("yellow");
            this.statusMatch.removeCssClass("red");

            if (this.showAlwaysTime){
                this.statusMatch.addControl(MatchUtil.getLocalizedDate(this.data,false,true).trim());
            }
            else if (MatchUtil.isPlaying(this.data)){
                this.statusMatch.addCssClass("green");
                this.statusMatch.addControl(MatchUtil.getShortStatus(this.data));
            }
            else if (MatchUtil.isFinished(this.data)){
                this.statusMatch.addControl(MatchUtil.getShortStatus(this.data));
            }
            else if (MatchUtil.isLivePenalty(this.data)) {
                this.statusMatch.addControl(MatchUtil.getShortStatus(this.data));
            }
            else if (MatchUtil.isPostponed(this.data)){
                this.statusMatch.addCssClass("red");
                this.statusMatch.addControl(MatchUtil.getShortStatus(this.data));
            }
            else{
                this.statusMatch.addControl(MatchUtil.getLocalizedScoreTime(this.data).trim());
            }

            if (MatchUtil.hasAggregatedScore(this.data)){
                if (this.data.aggregateWinner == 1){
                    this.scoreLocal.addControl(" [<p class='listViewMatchScoreAggrPen greentext'>" + MatchUtil.getLocalAggregateScore(this.data) + "</p>]");
                    this.scoreAway.addControl(" [<p class='listViewMatchScoreAggrPen'>" + MatchUtil.getAwayAggregateScore(this.data) + "</p>]");
                }
                else if(this.data.aggregateWinner == 2){
                    this.scoreLocal.addControl(" [<p class='listViewMatchScoreAggrPen'>" + MatchUtil.getLocalAggregateScore(this.data) + "</p>]");
                    this.scoreAway.addControl(" [<p class='listViewMatchScoreAggrPen greentext'>" + MatchUtil.getAwayAggregateScore(this.data) + "</p>]");
                }
                else {
                    this.scoreLocal.addControl(" [<p class='listViewMatchScoreAggrPen'>" + MatchUtil.getLocalAggregateScore(this.data) + "</p>]");
                    this.scoreAway.addControl(" [<p class='listViewMatchScoreAggrPen'>" + MatchUtil.getAwayAggregateScore(this.data) + "</p>]");
                }
            }

            if (MatchUtil.hasPenaltyScore(this.data)) {
                if (this.data.penaltyLocalTeam > this.data.penaltyVisitorTeam) {
                    this.scoreLocal.addControl(" [<p style='font-weight:normal;font-size:var(--text-size-xsmall);display:inline' class='greentext'>" + this.data.penaltyLocalTeam + "</p>]");
                    this.scoreAway.addControl(" [<p style='font-weight:normal;font-size:var(--text-size-xsmall);display:inline'>" + this.data.penaltyVisitorTeam + "</p>]");
                }
                else if (this.data.penaltyLocalTeam < this.data.penaltyVisitorTeam) {
                    this.scoreLocal.addControl(" [<p style='font-weight:normal;font-size:var(--text-size-xsmall);display:inline'>" + this.data.penaltyLocalTeam + "</p>]");
                    this.scoreAway.addControl(" [<p style='font-weight:normal;font-size:var(--text-size-xsmall);display:inline' class='greentext'>" + this.data.penaltyVisitorTeam + "</p>]");
                }
                else{
                    this.scoreLocal.addControl(" [<p style='font-weight:normal;font-size:var(--text-size-xsmall);display:inline' class='greentext'>" + this.data.penaltyLocalTeam + "</p>]");
                    this.scoreAway.addControl(" [<p style='font-weight:normal;font-size:var(--text-size-xsmall);display:inline'>" + this.data.penaltyVisitorTeam + "</p>]");
                }
            }

            if (this.renderVariant == 3) {
                var size = 28;
                this.homeImage.alt = this.data.localteam;
                this.awayImage.alt = this.data.visitorteam;

                if (AppInstance.getConfig().enabledFeatures.teamImages) {
                    this.homeImage.setImage(AppInstance.getConfig().url.imageBaseUrl + "/footapi/" + AppInstance.getConfig().url.teamPlayerImageUrl + "/teams_gs/" + this.data.gs_localteamid + "_small_rnd.png", size, size);
                    this.awayImage.setImage(AppInstance.getConfig().url.imageBaseUrl + "/footapi/" + AppInstance.getConfig().url.teamPlayerImageUrl + "/teams_gs/" + this.data.gs_visitorteamid + "_small_rnd.png", size, size);
                }
                else{
                    this.homeImage.setImage(AppInstance.getConfig().url.imageBaseUrl + "/footapi/" + AppInstance.getConfig().url.teamPlayerImageUrl + "/teams_gs/country/" + this.data.gs_localteamid + "_small_rnd.png", size, size);
                    this.awayImage.setImage(AppInstance.getConfig().url.imageBaseUrl + "/footapi/" + AppInstance.getConfig().url.teamPlayerImageUrl + "/teams_gs/country/" + this.data.gs_visitorteamid + "_small_rnd.png", size, size);
                }
            }
        }

        this.homeRedcard.setCss({"visibility":"hidden"});
        this.awayRedcard.setCss({"visibility":"hidden"});
        if (this.data.localteamrc) {
            this.homeRedcard.setCss({"visibility":""});
        }
        if (this.data.visitorteamrc) {
            this.awayRedcard.setCss({"visibility":""});
        }

        //TODO find out if below can be optimized
        if (!preventInstantRender) {
            if (this.isRendered) {
                this.contentPanel.invalidateDisplay();
            } else {
                //console.log("invalidate");
                //this.contentPanel.invalidateDisplay();
            }
        }
    }
};
export class CountryCompetitionItemRender extends  CountryItemRender{
    createComponents () {
        this.panel = new Panel();
        this.panel.setStyle("width:90%;margin-top:2px;padding-bottom:2px;border-bottom:1px solid #eeeeee;cursor:pointer;");
        this.addControl(this.panel);
        this.imageBox = new Panel();
        this.imageBox.setStyle("float:left;width:100px;margin-right:8px;");
        this.contents = new Panel();
        this.contents.setStyle("float:left;");
        this.panel.addControl(this.imageBox);
        this.panel.addControl(this.contents);

        //this.imageBox.addControl("<img style='width:40px' src=https://holoduke.nl/footapi/images/flags/"+this.data.country.replace(" ","-").toLowerCase()+".png?" + new Date().getTime()+"></img>");
        this.contents.addControl("<h6>" + this.data.leagueName);
        this.contents.addControl(this.data.developer);
        this.panel.bind('click', () => {
            window.lastScrollPosition = $(document).scrollTop();
            UrlManager.registerModule("subsection");
            UrlManager.setModule("subsection", "competition", true);
            UrlManager.registerModule("league");
            UrlManager.setModule("league", this.data.key);
            // UrlManager.registerModule("country");
            // UrlManager.setModule("country",encodeURIComponent(this.data.country.replace(" ","")));
            this.getPage().autoLoadSubSection();
            // this.getPage().getApplication().loadPage("PlayerDetail",{'id':this.data.id_gs});
        });
    }
};

export class TeamItemRender extends  CountryItemRender{
    createComponents () {
        this.panel = new Panel();
        this.panel.setStyle("width:90%;margin-top:2px;padding-bottom:2px;border-bottom:1px solid #eeeeee;cursor:pointer;");
        this.addControl(this.panel);
        this.imageBox = new Panel();
        this.imageBox.setStyle("float:left;width:70px;margin-right:8px;");
        this.contents = new Panel();
        this.contents.setStyle("float:left;");
        this.panel.addControl(this.imageBox);
        this.panel.addControl(this.contents);
        this.panel.addControl("<div style='clear:both'></div>");
        this.imageBox.addControl("<img style='width:60px' src=https://holoduke.nl/footapi/images/teams_gs/" + this.data.id + ".png?" + new Date().getTime() + "></img>");
        this.contents.addControl("<h6>" + this.data.name);
        this.contents.addControl(this.data.developer);
        this.panel.bind('click', () => {
            this.getPage().getApplication().loadPage("TeamDetail", {
                'id': this.data.id
            });
        });
    }
};
export class StandingsListRender extends  DataGridTileItemRender{

    init (alternativeRender) {
        super.init();
        this.alternativeRender = alternativeRender;

        var yearSelectContainer = new Panel();
        yearSelectContainer.setStyle("background-color:var(--listHeaderBackground);height:40px;width:100%;display:none;");
        this.addControl(yearSelectContainer);
        this.yearSelectContainer = yearSelectContainer;

        this.panel = new Panel();
        this.panel.addCssClass("flexHorizontalAlign");
        this.addControl(this.panel);
    }
    setData (data) {
        super.setData(data);

        console.log("--->",data,data);
        if (data.data.tableHistory && data.data.tableHistory.seasons.length) {
            this.yearSelectContainer.setCss({"display":"block"});

            var con = new Panel();
            con.setStyle("width:100%;justify-content: center;font-size: 15px;font-variant: all-small-caps;cursor:pointer;");
            con.addCssClass("flexHorizontalAlign");

            var back = new Panel();
            back.setStyle("display:inline;margin-right:auto;")
            back.addControl("2023");
            //con.addControl(back);

            var title = new Panel();

            if (this.data.data.historyTable) {
                title.addControl(Localizer.getString("season") + " " + data.data.historyTable.season);
            }
            else{
                title.addControl(Localizer.getString("season") + " " + data.data.table.season);
            }

            con.addControl(title);
            con.addControl("<p class='arrow-down-choice' style='margin-top:3px; margin-inline-start:6px;'></p>");

            var forward = new Panel();
            forward.setStyle("display:inline;margin-left:auto;")
            forward.addControl("2025");
            //con.addControl(forward);

            this.yearSelectContainer.clear();
            this.yearSelectContainer.addControl(con,true);

            this.yearSelectContainer.bind("click",()=>{
               this.listRender.triggerEvent("onRequestSeasonSelector");
            });
        }

        if (this.data.data.historyTable){
            var groupsData = this.data.data.historyTable.groups;
        }
        else{
            groupsData = this.data.data.table.groups;
        }

        console.log("create standings for group ",groups);
        this.panel.addControl("<table class='tableStandings'>");
        for (var i = 0; i < groupsData.length; i++) {
            var groups = groupsData[i];
            var row = new TableRow();
            row.setStyle("height:30px;")
            if (this.alternativeRender) {
                row.setStyle("height:24px;");
            }
            var th1 = new TableHeaderCol();
            var th1Arrow = new TableHeaderCol();
            var th2 = new TableHeaderCol();
            th1Arrow.setAttribute("colspan", 3);
            if (this.alternativeRender) {
                title1 = Localizer.getString("round") + " " + groups.round;
            } else {
                var title1 = groups.group.replace(data.data.info.title, "").replace(":", "").trim().trimStart();
                if (title1 && groups.round != 0) {
                    title1 += " - " + Localizer.getString("round") + " " + groups.round;
                } else {
                    if (groupsData.length > 1 && groups.round != 0) {
                        title1 = Localizer.getString("round") + " " + groups.round;
                    }
                    else if (groups.group && groups.group != data.data.info.title){
                        title1 = groups.group;
                    }
                    else if (data.data.info.stageName){
                        title1 = Localizer.getString(data.data.info.stageName);
                    }
                }
            }
            th1Arrow.addControl(title1);
            th1Arrow.setStyle("text-align:left;padding-left:4px;");
            var th3 = new TableHeaderCol();
            var th4 = new TableHeaderCol();
            th4.addControl(Localizer.getString("table_gamesplayed"));
            var th5 = new TableHeaderCol();
            th5.addControl(Localizer.getString("table_wins"));
            var th6 = new TableHeaderCol();
            th6.addControl(Localizer.getString("table_draw"));
            var th7 = new TableHeaderCol();
            th7.addControl(Localizer.getString("table_lost"));
            var th8 = new TableHeaderCol();
            th8.addControl(Localizer.getString("table_goalsforagainst"));
            th8.setStyle("min-width:41px;");
            var th9 = new TableHeaderCol();
            th9.addControl(Localizer.getString("table_goaldiff"));
            var th10 = new TableHeaderCol();
            th10.addControl(Localizer.getString("table_points"));
            if (!this.alternativeRender) {
                row.addControl(th1);
            }
            row.addControl(th1Arrow);
            //row.addControl(th2);
            //row.addControl(th3);
            row.addControl(th4);
            row.addControl(th5);
            row.addControl(th6);
            row.addControl(th7);
            row.addControl(th8);
            row.addControl(th9);
            row.addControl(th10);
            this.panel.addControl(row);

            for (var j = 0; j < groups.teams.length; j++) {
                var team = groups.teams[j];
                var row = new TableRow();
                var pos = new TableCol();
                var imageCon = new TableCol();
                if (!this.alternativeRender) {
                    pos.addControl(j + 1 + "");
                    if (team.color) {
                        pos.setStyle("border-top-right-radius: 8px;border-bottom-right-radius: 8px;background-color:" + team.color + "; color:" + Colorizer.getCorrectTextColor(team.color));
                    }
                    if (AppInstance.getConfig().enabledFeatures.teamImages) {
                        var image = new ImagePlaceHolder();
                        image.placeholderData = teamPlaceholder;
                        image.setCss({
                            'height': 36 + "px",
                            'width': 36 + "px"
                        });
                        image.setImage(AppInstance.getConfig().url.imageBaseUrl + "/footapi/" + AppInstance.getConfig().url.teamPlayerImageUrl + "/teams_gs/" + team.id_gs + "_small_rnd.png", 36, 36);
                        imageCon.addControl(image);
                    }
                } else {
                    row.setStyle("height:24px;");
                }
                var colArrow = new TableCol();
                if (team.position_change > 0) {
                    colArrow.addControl("<div class='arrow-up'></div>");
                } else if (team.position_change < 0) {
                    colArrow.addControl("<div class='arrow-down'></div>");
                }
                else{
                    //colArrow.setStyle("width:0%;display:none;");
                }
                var col1 = new TableCol();
                col1.addCssClass("standingsTeamName")
                if (this.alternativeRender) {
                    col1.addControl(Localizer.getString("position") + ": " + team.position);
                } else {
                    col1.addControl(team.team);
                }
                col1.setStyle("text-align: left;");
                if (team.isLive) {
                    col1.addCssClass("standingsLive");
                }
                var gp = new TableCol();
                gp.addControl(team.matchPoints + "");
                var w = new TableCol();
                w.addControl(team.totalWon + "");
                var d = new TableCol();
                d.addControl(team.totalDraw + "");
                var l = new TableCol();
                l.addControl(team.totalLost + "");
                var gf = new TableCol();
                gf.setStyle("font-size:10px;")
                gf.addControl(team.totalGoalsFor + "-" + team.totalGoalsAgainst);
                var gd = new TableCol();
                gd.addControl(team.totalGoalsFor - team.totalGoalsAgainst + "");
                var points = new TableCol();
                points.addControl(team.points + "");
                if (!this.alternativeRender) {
                    row.addControl(pos);
                }
                row.addControl(colArrow);
                row.addControl(imageCon);
                row.addControl(col1);
                row.addControl(gp);
                row.addControl(w);
                row.addControl(d);
                row.addControl(l);
                row.addControl(gf);
                row.addControl(gd);
                row.addControl(points);
                this.panel.addControl(row);
                (function (team, groups, alternativeRender) {
                    row.bind("click", function () {
                        if (alternativeRender) {
                            console.log(data);
                            var d = {
                                leagueKey: data.data.league.key
                            };
                            Router.showLeagueScreen(d, {
                                "action": "showStandings"
                            });
                        } else {
                            Router.showTeamScreen(team.id_gs);
                        }
                    });
                })(team, groups, this.alternativeRender);
            }
        }
        this.panel.addControl("</table>");
    }
};

export class MrecItemRender extends FocusableItemRender {
    getFocusable() {
        return this.panel;
    }

    init() {
        super.init();

        // Ad container
        this.adContainer = new Panel();
        this.adContainer.addCssClass("adContainer");
        this.adContainer.setCss({
            "display": "flex",
            "justify-content": "space-around",
        });
        this.addControl(this.adContainer)
    }

    setData(data) {

        this.adContainer.clear();

        // Create ad placeholder - you can replace this with actual ad integration
        const adContent = new Panel();
        adContent.addCssClass("mediumRectangleContent");
        adContent.setCss({
            "width": "100%",
            "height": "240px",
            "backgroundColor": "#f0f0f0", // Light grey placeholder
            "display": "flex",
            "justifyContent": "center",
            "alignItems": "center",
            "overflow": "hidden"
        });

        adContent.addControl(data.html);

        this.adContainer.addControl(adContent);
        console.log("render mrec ",data);

        this.invalidateDisplay();
    }
}

export class CountryLeagueListRender extends  FocusableItemRender{
    getFocusable() {
        return this.panel;
    }
    init () {
        super.init();
        this.panel = new Link();
        this.panel.addCssClass("listView");
        this.panel.addCssClass("flexHorizontalAlign flexAlignStart");
        this.addControl(this.panel);
        this.imageBox = new ImagePlaceHolder();
        this.imageBox.addCssClass("listViewCountryFlagItem");
        this.imageBox.placeholderData = countryPlaceHolder;
        this.contents = new Panel();
        this.contents.addCssClass("listViewCountryTitle");
        this.panel.addControl(this.imageBox);
        this.panel.addControl(this.contents);
        this.panel.setCss({
            "height": "50px"
        });
    }
    setData (data) {
        this.contents.clear();
        this.imageBox.alt = data.leagueName;
        this.imageBox.setImage(AppInstance.getConfig().url.imageBaseUrl + "/footapi/images/flags/" + data.country.replaceAll(" ", "-").toLowerCase() + ".png", 40, 40);
        this.contents.addControl(data.leagueName);
        this.panel.unbind("click");
        this.panel.bind("click", () => {
            Router.showLeagueScreen(data);
        });
        this.invalidateDisplay();
    }
};
export class NewsListRender extends  DataGridTileItemRender{
    init () {
        this.firstTime = true;
        super.init();
        this.panel = new Panel();
        this.panel.addCssClass("MatchStatsContainer");
        this.card = new Panel();
        this.card.addCssClass("MatchStatsContainerCard");
        this.panel.addControl(this.card);
        this.addControl(this.panel);
    }
    createComponents () {}
    setData (data) {
        this.card.clear();
        var formatted = Intl.DateTimeFormat(FootballManiaConfig.userLocale, {
            weekday: 'short',
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            hour: "numeric",
            minute: "numeric"
        }).format(new Date(data.ts * 1000));
        var date = new Panel();
        date.addControl("<i>" + formatted + "</i>");
        date.setStyle("font-size:11px;margin-inline-start: 8px;padding-top:8px;");
        var title = new Panel();
        title.addCssClass("MatchStatsTiteText MatchH2HTitle");
        title.setStyle("padding:8px;text-align:start;font-weight:bold;");
        title.addControl(data.title);
        this.card.addControl(title);
        this.card.addControl(date);

        if (AppInstance.getConfig().appmode == "ios") {
            var mainContainer = new Link();
            mainContainer.setHref(data.link+"?externalLink=1");
        }
        else{
            var mainContainer = new Panel();
            this.card.bind("click", () => {
                Router.openUrl(data.link, true);
            });
        }

        //mainContainer.setHref(data.link+"?externalLink=1");
        //mainContainer.addCssClass("flexHorizontalAlign");
        mainContainer.setStyle("padding-bottom:4px;");

        //window.l = this;
        //var w = this.getPage().getDimensions().width / 3;
        var w = 175;
        var h = w * 0.65;
        var image = new Image();
        image.setStyle("float:right;width:" + w + "px;height:" + h + "px;border-radius:4px;margin:8px;margin-top:-22px;display: inline-block");
        image.setImage(data.image);
        var description = new Panel();
        description.setStyle("margin-top:12px;margin:8px;text-align: start;line-height: 18px;");
        description.addControl(data.description);
        description.getTagName = function () {
            return 'p';
        };
        // var link = new Link();
        // link.setHref("http://www.google.com");
        // link.addControl("aaaaaaap");
        if (data.image != "http://holoduke.nl/footapi/news_visual_voetbalnieuws.jpg") {
            mainContainer.addControl(image);
        }
        mainContainer.addControl(description);
        //mainContainer.addControl(link);
        this.card.addControl(mainContainer);
        this.card.addControl("<div style='clear:both;width:96%;height:1px;background-color:var(--tab-border-color);margin:8px;'></div>");
    }
};
export class RankingItemRender extends  DataGridTileItemRender{
    init () {
        super.init();
        this.panel = new Panel();
        this.panel.addCssClass("MatchStatsContainer");
        this.panel.setStyle("margin-bottom:40px;");
        //
        this.card = new Panel();
        this.card.addCssClass("MatchStatsContainerCard");
        this.panel.addControl(this.card);
        this.panel.setStyle("margin-top:3px;margin-bottom:-3px;");
        this.card.setStyle("padding-bottom:4px;height:48px;cursor:pointer;");
        //
        this.content = new Panel();
        //this.content.addCssClass("flexHorizontalAlign");
        this.content.setStyle("height: 100%;");
        this.card.addControl(this.content);
        this.left = new Panel();
        this.left.addCssClass("flexHorizontalAlign flexAlignStart");
        this.right = new Panel();
        this.right.addCssClass("flexHorizontalAlign flexAlignEnd");
        this.position = new Panel();
        this.position.setStyle("min-width:20px;margin-inline-end: 4px;margin-inline-start: 4px;");
        this.image = new Image();
        this.image.setStyle("width:40px;height:40px;");
        this.name = new Panel();
        this.name.setStyle("font-weight:bold;margin-inline-end: 8px;margin-inline-start: 8px;");
        this.points = new Panel();
        if (FootballManiaConfig.isRtl) {
            this.points.setStyle("margin-inline-end: 8px;margin-inline-start: 8px;margin-right: auto;");
        } else {
            this.points.setStyle("margin-inline-end: 8px;margin-inline-start: 8px;margin-left: auto;");
        }
        this.left.addControl(this.position);
        this.left.addControl(this.image);
        this.left.addControl(this.name);
        this.left.addControl(this.points);
        this.content.addControl(this.left);
        this.content.addControl(this.right);
        this.panel.bind('click', () => {
            Router.showTeamScreen(this.data.id_gs);
        });
        this.addControl(this.panel);
    }
    setData (data) {
        this.data = data;
        this.panel.setCss({
            "height": data.style.height + 4 + "px"
        });
        this.position.clear();
        this.name.clear();
        this.points.clear();
        this.position.addControl(data.position, true);
        this.name.addControl(data.team, true);
        this.image.setImage(AppInstance.getConfig().url.imageBaseUrl + "/footapi/" + AppInstance.getConfig().url.teamPlayerImageUrl + "/teams_gs/" + data.id_gs + "_small_rnd.png", 40, 40);
        this.image.setAttribute("width", 40);
        this.image.setAttribute("height", 40);
        this.points.addControl(data.points, true);

    }
};